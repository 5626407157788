import {
  getFooterData,
  getHeaderData,
  getPageData,
  getRedirectIfExists,
} from 'api'

import { normalizeData } from 'api/utils'
import { SectionBuilder } from 'components/organisms'
import { default as normalizeFooterData } from 'models/Footer'
import { default as normalizeHeaderData } from 'models/Header'

import {
  checkIfSlugExixst,
  generateFileWithPath,
  getFSUtils,
} from 'utils/ssr-functions'
export const staticPathFileName = 'static-paths'

export const getServerSideProps = async ({ ...params }) => {
  console._times.clear()
  console.clear()
  console.time('[ ⏱️ `getServerSideProps` TIME ]')
  const { res, resolvedUrl, query, req } = params
  const fsUtils = getFSUtils()

  // Cache-Control
  res.setHeader(
    'Cache-Control',
    'public,max-age=1800, s-maxage=10, stale-while-revalidate=59',
  )

  // Ottieni lo slug dalla query
  const slugToCheck = !query.slug ? '/' : '/' + query.slug.join('/')

  let itemFound,
    forceGeneration = false
  // Get/Generate static paths file and check if the slug exist
  do {
    console.time('[ ⏱️ GENERATION TIME ]')
    const staticPathFile = await generateFileWithPath(
      forceGeneration,
      fsUtils,
      staticPathFileName,
    )
    console.timeEnd('[ ⏱️ GENERATION TIME ]')
    const slugFound = checkIfSlugExixst(slugToCheck, staticPathFile)
    itemFound =
      slugFound !== undefined
        ? slugFound
        : checkIfSlugExixst(resolvedUrl, staticPathFile)

    // Maybe is a new page, try to generate the file again
    if (itemFound === undefined && !forceGeneration) {
      forceGeneration = true
      continue
    }
    if (itemFound === undefined && forceGeneration) break

    const { entity, slug, isHomepage, relativePath, webUrl } = itemFound

    console.info(`[ ℹ️ API CALL FOR PAGE: ${slug} (${entity})]`)
    console.time('\t[ ⏱️ API CALL PAGE TIME ]')
    const [page, footer, header] = await Promise.all([
      getPageData({
        entity,
        slug: isHomepage ? 'homepage' : slug,
      }),
      getFooterData(),
      getHeaderData(),
    ])
    console.timeEnd('\t[ ⏱️ API CALL PAGE TIME ]')

    const sections = await normalizeData(page, itemFound)

    console.timeEnd('[ ⏱️ `getServerSideProps` TIME ]')
    console.info(`[ ✅ PAGE FOUND: ${slug} ]\n\n`)
    if (!page || !page.slug || !page.title) {
      return { notFound: true }
    }
    return {
      props: {
        title: page?.title?.rendered,
        slug: page?.slug,
        sections: sections || [],
        footer: normalizeFooterData(footer) ?? {},
        header: normalizeHeaderData(header) ?? {},
        options: {},
        additionalData: {},
      },
    }
  } while (!itemFound && !itemFound?.id)

  console.timeEnd('[ ⏱️ `getServerSideProps` TIME ]')
  console.info(`[ ℹ️ PAGE NOT FOUND, CHECK FOR REDIRECT ]\n\n`)

  const redirect = await getRedirectIfExists(resolvedUrl)
  if (redirect) return redirect
  console.info(`[ ❌ PAGE AND REDIRECT NOT FOUND: ${resolvedUrl} ]\n\n`)
  return { notFound: true }
}

export default function Page({
  title,
  sections,
  header,
  footer,
  slug,
  ...props
}) {
  console.groupCollapsed(` \u001b[31m  PAGE: "${title}" - "${slug}"`)
  console.info('\u001b[33m - HEADER: ', `${typeof header}`)
  console.info('\u001b[32m - SECTIONS: ', sections)
  console.info('\u001b[36m - FOOTER: ', typeof footer)
  console.info('\u001b[0m')
  console.groupEnd()

  return <SectionBuilder data={sections} />
}
